import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { useSignUpModal } from 'components/SignUpModal';
import { SaveToProjectListModal } from 'components/SaveToList/SaveToProjectListModal';
import { useModal } from 'mui-modal-provider';
import { ContentListsItemType } from 'lib/types';
import { trackClickSignupEvent } from 'lib/hooks/useEventTracking';
import { TeamFeatureButton } from 'components/TeamFeatureButton';
import { TeamsProvider } from 'lib/contexts/teamsContext';

interface Props {
  itemId: number;
  /** The team id to use if not the current team, useful to override the app state teamId
   * for objects like jobs that have a team associated already */
  teamId?: number;
  itemType: ContentListsItemType;
  text?: string;
  styling?: string;
  context: 'project' | 'list';
}

export const SaveToProjectListButton = ({
  itemId, teamId, itemType, text, styling, context,
}: Props) => {
  const currentUser = useCurrentUser();
  const API = useApi();
  const { showSignUpModal } = useSignUpModal();
  const { showModal } = useModal();

  const click = () => {
    if (!currentUser) {
      trackClickSignupEvent(API, 'save-to-project-list');
      showSignUpModal(`Please sign up or sign in to save ${itemType}s`);
      return;
    }
    const modal = showModal(SaveToProjectListModal, {
      itemId,
      teamId,
      itemType,
      onClose: () => {
        modal.destroy();
      },
      context,
    });
  };

  return (
    <TeamsProvider>
      <div className="object-cover w-full">
        <TeamFeatureButton
          onSuccess={click}
          title={text || 'Add post to project'}
          className={styling || 'hover:bg-hintgray/50 rounded-full w-full text-left'}
        />
      </div>
    </TeamsProvider>
  );
};
