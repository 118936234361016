import { useApplicationState } from 'lib/contexts/ApplicationState';
import { useCreateNewTeamModal, usePaidSubscriptionModal } from 'components/UpgradeModal';
import { goToLogin, goToSignup } from 'lib/redirect';
import router from 'next/router';
import { useTeams } from 'lib/contexts/teamsContext';
import { slugify, teamIsPaidFullTeamsPlan, teamIsPaidStarterPlan } from 'lib/helpers';
import { trackClickSignupEvent } from 'lib/hooks/useEventTracking';

interface Props {
  onSuccess: (teamId?: number) => void;
  title: string;
  className?: string;
  /**
   * The minimum plan required to access the feature.
   * The default is 'starter' */
  minimumPlan?: 'teams' | 'starter';
}

/**
 * A button component for handling redirecting to a different page for a team feature.
 **/
export const TeamFeatureButton = ({
  onSuccess, title, className = 'btn-primary', minimumPlan = 'starter',
}: Props) => {
  const { currentUser, isAuthed, api } = useApplicationState();
  const teams = useTeams();
  const { showPaidSubscriptionModal } = usePaidSubscriptionModal();
  const { showCreateNewTeamModal } = useCreateNewTeamModal();

  const handleClick = () => {
    const { teamId } = router.query;
    const teamCheck = minimumPlan === 'teams'
      ? teamIsPaidFullTeamsPlan
      : teamIsPaidStarterPlan;

    /** Get the active team subscription for the team specified by teamId */
    let activeTeamSubscription = teams?.find(
      (team) => Number(teamId) === team.id && teamCheck(team),
    );

    if (!activeTeamSubscription && !teamId) {
      // There is no team specified so we'll get the first paid team
      activeTeamSubscription = teams?.find(teamCheck);
    }

    // TODO: This would be a good place to check if the user is accessing via the iOS app.
    // If so and they don't have a subscription, we can show a message that they should
    // use a browser to upgrade to teams.
    if (activeTeamSubscription) {
      // The user is on a paid team, call the onSuccess callback
      onSuccess(activeTeamSubscription.id);
    } else if ((teams?.length || 0) >= 1) {
      // The user is on a team, but it doesn't have an active subscription, so prompt to subscribe
      showPaidSubscriptionModal(`Start your Teams subscription to ${title.toLowerCase()}`, teams?.[0]?.id);
    } else if (currentUser) {
      // The user is not on a team, prompt to create a new team
      showCreateNewTeamModal(`Create a new team to ${title.toLowerCase()}`, (id) => {
        // Force a page reload to ensure the current user and new team are loaded
        window.location.href = `/teams/${id}/billing`;
      });
    } else {
      // No current user, prompt to login/signup
      // TODO: combine into one helper ie goToLoginOrSignup()
      trackClickSignupEvent(api, `team-feature-button-${slugify(title.toLowerCase())}`);
      goToLogin(currentUser, router, router.asPath);
      goToSignup(currentUser, router);
    }
  };

  /** We don't want the user to be able to click the button before
   * current user and api is resolved */
  const disabled = !isAuthed || !api;

  return (
    <button type="button" disabled={disabled} className={className} onClick={handleClick}>
      {title}
    </button>
  );
};
