import { useState, useEffect } from 'react';
import {
  Dialog, IconButton,
  List as MuiList, ListItem, ListItemButton,
  ListItemIcon, ListItemText,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import { useApi, useCurrentUser, useCurrentTeamId } from 'lib/contexts/ApplicationState';
import { ContentList, ContentListsItemType } from 'lib/types';
import Link from 'next/link';

interface Props {
  itemId: number;
  /** The team id to use if not the current team, useful to override the app state teamId
   * for objects like jobs that have a team associated already */
  teamId?: number;
  itemType: ContentListsItemType;
  onClose: () => void;
  context: 'project' | 'list';
}

export const SaveToProjectListModal = ({
  itemId, itemType, teamId, onClose, context,
}: Props) => {
  const currentUser = useCurrentUser();
  const API = useApi();
  const currentTeamId = useCurrentTeamId();
  const { enqueueSnackbar } = useSnackbar();

  const [isSendingData, setIsSendingData] = useState(false);
  const [lists, setLists] = useState<any[]>([]);

  useEffect(() => {
    const fetchLists = async (_teamId: number) => {
      const result = await API.getProjectLists(_teamId, itemId, itemType);
      setLists(result);
    };

    if (currentUser && (currentTeamId || teamId)) {
      const teamIdToUse = teamId || currentTeamId;
      if (!teamIdToUse) return;
      fetchLists(teamIdToUse);
    }
  }, [currentUser, currentTeamId]);

  const addToList = async (list: ContentList) => {
    console.log(list);
    try {
      setIsSendingData(true);
      await API.addItemToList(list.id, itemId, itemType);
      setIsSendingData(false);
      onClose();
      enqueueSnackbar(`Added to ${list.name}!`, {
        variant: 'success',
        autoHideDuration: 9000,
        action: (
          <Link href={`/teams/${teamId || currentTeamId}/projects/${list.projectId}`}>
            View project
          </Link>
        ),
      });
    } catch (error) {
      enqueueSnackbar(`Error adding ${itemType} to list: ${error}`, {
        variant: 'error',
      });
    }
  };

  const removeFromList = async (list: ContentList) => {
    try {
      setIsSendingData(true);
      await API.removeItemFromList(list.id, itemId);
      setIsSendingData(false);
      onClose();
      enqueueSnackbar(`Removed from ${list.name}`, {
        variant: 'success',
        action: (
          <Link href={`/teams/${teamId || currentTeamId}/projects/${list.projectId}`}>
            View project
          </Link>
        ),
      });
    } catch (err) {
      setIsSendingData(false);
      enqueueSnackbar(`Error removing ${itemType} from list: ${err}`, {
        variant: 'error',
      });
    }
  };

  const save = async (list: ContentList) => {
    if (!list.id) return;
    if (list.contentListItemId) {
      removeFromList(list);
      return;
    }
    addToList(list);
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <div className="absolute right-2 top-2 text-orange">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="p-4">
        <>
          <div className="flex flex-wrap sm:flex-nowrap items-center">
            <h1 className="text-lg text-orange font-bold mr-5">
              Save
              {' '}
              {itemType}
              {' '}
              to a
              {' '}
              {context}
            </h1>
          </div>
          <div>
            <MuiList>
              {currentTeamId && !lists.length && (
                <div className="text-center p-2">
                  <p className="mb-2">
                    No projects found for this team.
                  </p>
                  <Link className="btn-primary" href={`/teams/${teamId || currentTeamId}/projects/new`}>
                    Create a project
                  </Link>
                </div>
              )}
              {lists.map((l) => (
                <ListItem key={l.id} disablePadding>
                  <ListItemButton
                    disabled={isSendingData}
                    onClick={() => save(l)}
                  >
                    <ListItemIcon>
                      {(l.contentListItemId) ? <CheckIcon color="success" /> : <AddIcon color="inherit" />}
                    </ListItemIcon>
                    <ListItemText>{l.name}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </MuiList>
          </div>
        </>
      </div>
    </Dialog>
  );
};
