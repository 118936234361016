import {
  createContext, useContext, useEffect, ReactNode, useState,
} from 'react';
import { Team } from 'lib/types/teams';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';

type ProviderProps = { children: ReactNode };

const TeamsContext = createContext<Team[] | undefined>(undefined);

/** Returns the teams where the user is an active member or manager */
function useTeams() {
  return useContext(TeamsContext);
}

function TeamsProvider({ children }: ProviderProps) {
  const [teams, setTeams] = useState<Team[] | undefined>();

  const API = useApi();
  const currentUser = useCurrentUser();

  useEffect(() => {
    const getTeams = async () => {
      if (!currentUser) {
        return;
      }

      const data = await API.getTeams();
      setTeams(data || []);
    };
    if (!teams) getTeams();
  }, [currentUser]);

  return (
    <TeamsContext.Provider value={teams}>
      {children}
    </TeamsContext.Provider>
  );
}

export { TeamsProvider, useTeams };
